"use client";
import { Box, LoadingBackdrop, SignInAndForgetPwd, clearAuthTokens } from "@hkexpressairwayslimited/ui";
import { getAnalyticPage, pushAdobeDataLayer } from "analytic";
import { memberAuthEndPoints, parseJwt, postApi, postMessageToMobile } from "api";
import useCountryCodeOptions from "modules/common/hooks/useCountryCode";
import { useTransContent } from "modules/common/trans-content/transContent";
import { fetchTokenSuccess } from "modules/features/auth/actions/authAction";
import { signInApple, signInCx } from "modules/features/auth/service";
import { useParams, useRouter } from "next/navigation";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

export enum SignInFromType {
  IBE = "IBE",
  MMB = "MMB",
}
interface CustomPageProps {
  onSignInSucceed?: () => void;
  isDisplayClose?: boolean;
  onCloseLoginNow?: () => void;
  onCXMemberClick?: () => void;
  from?: SignInFromType;
}
export default function SignInPage({
  onSignInSucceed,
  isDisplayClose,
  onCloseLoginNow,
  onCXMemberClick,
  from,
}: CustomPageProps) {
  const dispatch = useDispatch();
  const lang = useParams().lang || "";
  // const { isMobile, isTablet, isDesktop } = useMediaQueries();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [signInErrorMsg, setSignInErrorMsg] = useState<string | JSX.Element | JSX.Element[]>();
  const router = useRouter();
  const [forgetPwdErrorPwdMsgMsg, setForgetPwdErrorPwdMsgMsg] = useState<string | JSX.Element | JSX.Element[]>();
  const { t } = useTransContent();

  useEffect(() => {
    if (!onSignInSucceed) {
      document.title = t("web.B2B.Login.sign.button") as string;
    }
  }, [onSignInSucceed, t]);

  const showTechnicalErrorMsgSignIn = useCallback(() => {
    setSignInErrorMsg(t("web.member.signIn.messageBox.technicalError"));
  }, [t]);
  const showTechnicalErrorMsgForgetPwd = useCallback(() => {
    setForgetPwdErrorPwdMsgMsg(t("web.member.signIn.messageBox.technicalError"));
  }, [t]);

  const onSignIn = useCallback(
    async (formValues: any) => {
      if (formValues && formValues.data) {
        setIsLoading(true);
        setSignInErrorMsg("");
        clearAuthTokens();
        const {
          email,
          password,
          mobile: { countryCode, mobileNumber },
          method,
        } = formValues.data;
        if (
          !method ||
          (method === "email" && !email) ||
          (method === "phone" && (!countryCode || !mobileNumber)) ||
          !password
        ) {
          console.error("Missing Required Fields");
          setIsLoading(false);
          showTechnicalErrorMsgSignIn();
          return false;
        }
        try {
          const response = await postApi(
            memberAuthEndPoints.signIn,
            {
              username: method === "email" ? email : `+${countryCode}${mobileNumber}`,
              password: password,
              login_type: method === "email" ? "EMAIL" : "PHONE",
            },
            undefined,
            {
              credentials: true,
            },
            true,
            false
          );
          if (response.metadata.status_code === 200) {
            const { access_token, refresh_token, id_token, mlc_access_token, complete_profile_token } = response;
            if (complete_profile_token) {
              router.push(`/member/complete-profile?complete_profile_token=${complete_profile_token}`);
              return;
            }
            if (response.redirect_url) {
              signInCx(undefined, true);
              setIsLoading(false);
              return;
            }
            dispatch(
              fetchTokenSuccess({
                accessToken: access_token,
                refreshToken: refresh_token,
                idToken: id_token,
                mlcAccessToken: mlc_access_token,
              })
            );

            if (window.ReactNativeWebView) {
              postMessageToMobile({
                accessToken: access_token,
                idToken: id_token,
                refreshToken: refresh_token,
                mlcAccessToken: mlc_access_token,
              });
              return;
            }

            // adobe analytic mmb
            from &&
              pushAdobeDataLayer({
                event: "login_success",
                page: getAnalyticPage({
                  name: from === SignInFromType.IBE ? "booking::login success page" : "mmb::login page",
                }),
                member: {
                  id: parseJwt(access_token).username || "",
                  login_type: method === "email" ? "email" : "mobile",
                },
              });

            setIsLoading(false);
            // TODO check profile complete flag from api
            if (onSignInSucceed) {
              onSignInSucceed();
            } else {
              router.push(`/member/profile`);
            }
            return;
          } else {
            showTechnicalErrorMsgSignIn();
          }
          setIsLoading(false);
        } catch (err: any) {
          console.error(err);
          if (err.metadata.status_code === 400 || err.metadata.status_code === 401) {
            if (err.error_code === "NOT_AUTHORIZED" || err.error_code === "USER_DOESNT_EXIST") {
              setSignInErrorMsg("NOT_AUTHORIZED");
            } else {
              showTechnicalErrorMsgSignIn();
            }
          } else {
            showTechnicalErrorMsgSignIn();
          }
          setIsLoading(false);
        }
      }
    },
    [setIsLoading, onSignInSucceed, showTechnicalErrorMsgSignIn, dispatch, from, router]
  );

  const onForgetPassword = useCallback(
    async (formValues: any) => {
      if (formValues && formValues.data) {
        setIsLoading(true);
        const {
          method,
          email,
          mobile: { countryCode, mobileNumber },
        } = formValues.data;
        if (!method || (method === "email" && !email) || (method === "phone" && (!countryCode || !mobileNumber))) {
          setIsLoading(false);
          showTechnicalErrorMsgForgetPwd();
          return false;
        }
        try {
          const response = await postApi(
            memberAuthEndPoints.forgotPassword,
            {
              username: method === "email" ? email : `+${countryCode}${mobileNumber}`,
              username_type: method === "email" ? "EMAIL" : "PHONE",
            },
            undefined,
            undefined,
            true
          );
          if (response.metadata.status_code === 200) {
            if (method === "email") {
              setForgetPwdErrorPwdMsgMsg(t("web.member.signIn.forgetPassword.sent.email"));
            } else {
              setForgetPwdErrorPwdMsgMsg(t("web.member.signIn.forgetPassword.sent.mobile"));
            }
          } else {
            showTechnicalErrorMsgForgetPwd();
          }
          setIsLoading(false);
        } catch (err: any) {
          console.error(err);
          if (err.metadata.status_code === 400 || err.metadata.status_code === 401) {
            if (err.error_code === "NOT_AUTHORIZED" || err.error_code === "USER_DOESNT_EXIST") {
              setForgetPwdErrorPwdMsgMsg("NOT_AUTHORIZED");
            } else {
              showTechnicalErrorMsgForgetPwd();
            }
          } else {
            showTechnicalErrorMsgForgetPwd();
          }
          setIsLoading(false);
        }
        setIsLoading(false);
      }
    },
    [setIsLoading, showTechnicalErrorMsgForgetPwd, t]
  );

  const handleCxMemberClick = useCallback(() => {
    onCXMemberClick ? onCXMemberClick() : signInCx("/");
  }, [onCXMemberClick]);
  const handleAppleMemberClick = useCallback(() => {
    signInApple("/");
  }, []);
  const COUNTRY_CODE = useCountryCodeOptions();

  const i18nContent = useMemo(
    () => ({
      title: t("web.member.signIn.title"),
      desc: t("web.member.signIn.desc"),
      method: {
        options: [
          {
            value: "email",
            label: t("web.member.signIn.method.email"),
          },
          {
            value: "phone",
            label: t("web.member.signIn.method.number"),
          },
        ],
      },
      emailField: {
        helperText: t("web.member.signIn.emailField.helperText") + "*",
        placeholder: t("web.member.signIn.method.email"),
        required: t("web.flightBook.contactsEmail") as string,
      },
      mobilePhoneField: {
        helperText: t("web.member.signIn.mobilePhoneField.helperText") + "*",
        countryCode: {
          // placeholder: t("web.member.signIn.mobilePhoneField.countryCode.placeholder"),
          required: t("web.flightBook.detail.courtesy.required") as string,
        },
        placeholder: t("web.flightBook.detail.mobile"),
        required: t("web.flightBook.detail.mobile.required") as string,
        options: COUNTRY_CODE,
      },
      passwordField: {
        helperText: t("web.member.signIn.passwordField.helperText") + "*",
        placeholder: t("web.login.password.placeholder"),
        required: t("web.member.signIn.passwordField.required") as string,
      },
      fwd: t("web.B2B.Login.forget.paw"),
      submit: t("web.member.signIn.submit"),
      text: t("web.member.signIn.text"),
      link: t("web.member.signIn.link"),
      other: t("web.member.signIn.other"),
      cxText: t("web.member.signIn.cxText"),
      appleText: t("web.member.signIn.appleText"),
    }),
    [t, COUNTRY_CODE]
  );

  const i18nContentPassword = useMemo(
    () => ({
      title: t("web.member.forgotPassword.title") as string,
      text: t("web.member.forgotPassword.text") as string,
      submit: t("web.member.login.forgetPassword.text") as string,
      cxText: t("web.member.forgotPassword.cxText") as string,
      appleText: t("web.member.forgotPassword.appleText") as string,
      backToSignInText: t("web.member.forgotPassword.backTo") as string,
      method: {
        options: [
          {
            value: "email",
            label: t("web.member.signIn.method.email") as string,
          },
          {
            value: "phone",
            label: t("web.member.signIn.method.number") as string,
          },
        ],
      },
      emailField: {
        helperText: (t("web.member.signIn.emailField.helperText") as string) + "*",
        placeholder: t("web.member.signIn.method.email") as string,
        required: t("web.flightBook.contactsEmail") as string,
      },
      mobilePhoneField: {
        helperText: (t("web.member.signIn.mobilePhoneField.helperText") as string) + "*",
        countryCode: {
          placeholder: t("web.member.signIn.mobilePhoneField.countryCode.placeholder") as string,
          required: t("web.flightBook.detail.courtesy.required") as string,
        },
        mobileNumber: {
          placeholder: t("web.flightBook.detail.mobile" as string),
          required: t("web.flightBook.detail.mobile.required") as string,
        },
        options: COUNTRY_CODE,
      },
    }),
    [t, COUNTRY_CODE]
  );

  return (
    <>
      <Box>
        <SignInAndForgetPwd
          onSignIn={onSignIn}
          onForgetPassword={onForgetPassword}
          signInErrorMsg={signInErrorMsg}
          forgetPwdErrorPwdMsgMsg={forgetPwdErrorPwdMsgMsg}
          isDisplayClose={isDisplayClose}
          onCloseLoginNow={onCloseLoginNow}
          i18nContent={i18nContent}
          i18nContentPassword={i18nContentPassword}
          handleCxMemberClick={handleCxMemberClick}
          handleAppleMemberClick={handleAppleMemberClick}
        ></SignInAndForgetPwd>
        <LoadingBackdrop isOpen={isLoading} />
      </Box>
    </>
  );
}
