"use client";
// Logger Initialization
import "loggers";
//
import { initApp } from "modules/common/common/actions/initAction";
import ErrorDialogContainer from "modules/common/error-dialog/containers/ErrorDialogContainer";
import { LoadingBackdrop } from "modules/common/loading-backdrop/containers/LoadingBackdrop";
import { Header } from "modules/features/frame";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Provider } from "react-redux";

import { I18nInstance } from "modules/common/common/utils/i18n-utils";
import { i18nInit } from "modules/common/i18n/init";
import { RouterInstance } from "router/router-utils";
import store from "../store";

import HCaptcha from "@hcaptcha/react-hcaptcha";
import {
  AppContext,
  ESpacing,
  Font,
  HKEStyleProvider,
  LoadingBackdrop as LoadingBackdropUi,
  MessageBox,
  Modal,
  ModalHandle,
  Text,
} from "@hkexpressairwayslimited/ui";

import { envConfig } from "env";
import i18next, { Resource } from "i18next";
import { getHCaptchaSetting } from "lib/features/flight-book/booking/service";
import initClientServiceWorker from "mocks/clientServiceWorker";
import { hCaptchaInstance } from "modules/common/common/utils/hcaptcha-utils";
import { closeLoadingBackdrop } from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import { useTransContent } from "modules/common/trans-content/transContent";
import { fetchTokenSuccess } from "modules/features/auth/actions/authAction";
import {
  closeSignInPopup,
  resetApiErrorCodes,
  selectApiErrorCodes,
  selectAppReady,
  selectSignInPopup,
} from "modules/global/general";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { ConfirmErrorModal } from "ui/features/common/ConfirmErrorModal";
import { DetailErrorModal } from "ui/features/common/DetailErrorModal";
import { MessageErrorModal } from "ui/features/common/MessageErrorModal";
import SignInPage from "ui/features/member/SignInPage";

initClientServiceWorker();

const persistor = persistStore(store);

function ClientLayout({
  children,
  i18nResources,
  blurImageList = {},
  params,
}: {
  children: ReactNode;
  i18nResources?: Resource;
  blurImageList?: Record<string, string>;
  params: { lang: string };
}) {
  const searchParams = useSearchParams();
  const isAutomatedTest = useMemo(() => searchParams.get("autoTest") === "true", []);
  const i18n = useMemo(() => {
    if (!i18next.isInitialized) {
      return i18nInit(params.lang, !isAutomatedTest ? i18nResources : undefined, isAutomatedTest);
    } else if (i18next.language !== params.lang) {
      if (i18nResources && !isAutomatedTest) {
        i18next.addResourceBundle(params.lang, "translation", i18nResources[params.lang].translation, true, true);
      }
      i18next.changeLanguage(params.lang);
    }
    return i18next;
  }, [i18nResources, params.lang, isAutomatedTest]);
  const { t, ready } = useTransContent();
  const [apiErrorModalHandle, setApiErrorModalHandle] = useState<ModalHandle | null>();
  const [appReady, setAppReady] = useState(false);
  const router = useRouter();
  const pathname = usePathname();
  const device = useMemo(
    () => searchParams.get("device"),
    [
      // no need to re-run this effect when searchParams changes
    ]
  );
  const [hCaptchaConfig, setHCaptchaConfig] = useState<Awaited<ReturnType<typeof getHCaptchaSetting>>>();
  const [apiErrorCodes, setApiErrorCodes] = useState<string[]>([]);
  const [signInPopup, setSignInPopup] = useState<boolean>(false);
  const [signInModalHandle, setSignInModalHandle] = useState<ModalHandle | null>();
  const appContextValue = useMemo(() => ({ isInEditor: false, isPreview: false, blurImageList }), [blurImageList]);
  const [hCaptcha, setHCaptcha] = useState<HCaptcha | null>(null);

  const handleCloseSignInPopup = useCallback(() => {
    store.dispatch(closeSignInPopup());
  }, []);

  useEffect(() => {
    const initHCaptchaSetting = async () => {
      const hCaptchaConfig = await getHCaptchaSetting();

      setHCaptchaConfig(hCaptchaConfig);
    };
    hCaptchaInstance.setInstance(hCaptcha);
    initHCaptchaSetting();
  }, [hCaptcha]);

  useEffect(() => {
    if (hCaptchaConfig) {
      hCaptchaInstance.setConfig(hCaptchaConfig);
    }
  }, [hCaptchaConfig]);

  useEffect(() => {
    I18nInstance.setI18nInstance(i18n);
  }, [i18n]);

  useEffect(() => {
    RouterInstance.setRouterInstance(router);
  }, [router]);

  useEffect(() => {
    store.subscribe(() => {
      const state = store.getState();

      console.log("selectAppReady", state, selectAppReady(state));
      setApiErrorCodes(selectApiErrorCodes(state));
      setSignInPopup(selectSignInPopup(state));
      setAppReady(selectAppReady(state));
    });
    store.dispatch(initApp());
  }, []);

  useEffect(() => {
    if (signInModalHandle?.showModal === false && signInPopup) {
      signInModalHandle?.open();
    } else if (signInModalHandle?.showModal === true && !signInPopup) {
      signInModalHandle?.close();
    }
  }, [setSignInModalHandle, signInModalHandle, signInPopup]);

  useEffect(() => {
    const handleMessage = (event: any) => {
      try {
        const { type, payload } = JSON.parse(event.data);
        if (type === "UO_INIT") {
          if (payload.access_token) {
            store.dispatch(
              fetchTokenSuccess({
                accessToken: payload.access_token,
                idToken: payload.id_token,
                refreshToken: payload.refresh_token,
                mlcAccessToken: payload.mlc_access_token,
              })
            );
          }
        }
      } catch (err) {
        // ignore
      }
      // console.log('Received message:', event.data);
      // alert(`Received Data ${JSON.parse(event.data).type} ${JSON.parse(event.data).payload}`);
    };

    window.addEventListener("message", handleMessage);

    // if (window.ReactNativeWebView) {
    //   window.ReactNativeWebView.postMessage(
    //     JSON.stringify({
    //       type: "UO_INIT",
    //     })
    //   );
    // }

    // Cleanup to remove event listener
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (apiErrorCodes.length >= 1) {
      store.dispatch(closeLoadingBackdrop());
      apiErrorModalHandle?.open();
    }
  }, [apiErrorCodes, apiErrorModalHandle]);

  const enableCustomMessage = useMemo(() => {
    if (apiErrorCodes.includes("This promo code does not exist or is not valid")) {
      return true;
    }
    return false;
  }, [apiErrorCodes]);

  const handleCloseApiErrorModal = useCallback(() => {
    if (apiErrorCodes.includes("This promo code does not exist or is not valid")) {
      RouterInstance.replace("/:lang");
    }
    apiErrorModalHandle?.close();
    store.dispatch(resetApiErrorCodes());
  }, [apiErrorModalHandle, apiErrorCodes]);

  const multipleTabsPage = useMemo(() => ["flight-booking"], []);

  useEffect(() => {
    const path = pathname.split("/")[2];
    let channel: BroadcastChannel;
    if (multipleTabsPage.includes(path)) {
      channel = new BroadcastChannel("channel");

      channel.onmessage = (event) => {
        if (event.data === "firstTab") {
          channel.postMessage("multipleTabs");
        } else {
          RouterInstance.replace("/multipleTabs");
        }
      };

      channel.postMessage("firstTab");
    }

    return () => {
      channel?.close();
    };
  }, [multipleTabsPage, pathname]);

  const ctaText = useMemo(() => {
    if (apiErrorCodes.includes("This promo code does not exist or is not valid")) {
      return t("web.flightBook.flightSelect.startNewPopup.button.startNew");
    }
    return t("web.general.apiError.ctaText");
  }, [t, apiErrorCodes]);

  useEffect(() => {
    // if (engage !== undefined) {
    // Send VIEW event
    // }
  }, []);

  if (typeof window !== "undefined") {
    console.info("appContextValue", appContextValue);
  }

  const app = (
    <div id='hkexpress'>
      {/** Loading Backdrop */}
      {<LoadingBackdrop />}

      {/** Error dialog */}
      {<ErrorDialogContainer />}

      {/** Header */}
      {device !== "app" ? <Header /> : <></>}

      <main>{children}</main>

      <Modal noPadding autoWidth ref={setSignInModalHandle} onClose={handleCloseSignInPopup}>
        <SignInPage onSignInSucceed={handleCloseSignInPopup} />
      </Modal>

      <MessageBox
        id='apiError'
        ref={setApiErrorModalHandle}
        title={t("web.general.apiError.title")}
        ctaText={ctaText}
        onClickCta={handleCloseApiErrorModal}
        onClose={handleCloseApiErrorModal}
      >
        <Text>
          {!enableCustomMessage
            ? t("web.general.apiError.content", { errorCodes: apiErrorCodes.join(", ") })
            : apiErrorCodes.join(", ")}
        </Text>
      </MessageBox>

      <MessageErrorModal />
      <ConfirmErrorModal />
      <DetailErrorModal />
    </div>
  );

  useEffect(() => {
    window.hCaptchaConfig = hCaptchaConfig;
  }, [hCaptchaConfig]);

  return (
    <HKEStyleProvider isMobile={device === "app"}>
      <AppContext.Provider value={appContextValue}>
        <Provider store={store}>
          {envConfig.hCaptchaSiteKey && hCaptchaConfig?.isEnable && (
            <HCaptcha size='invisible' ref={setHCaptcha} sitekey={envConfig.hCaptchaSiteKey} />
          )}
          <LoadingBackdropUi isOpen={!appReady || !ready} />
          <PersistGate persistor={persistor}>{(persisted) => <>{app}</>}</PersistGate>
          {hCaptchaConfig?.isEnable && (
            <Font mt={ESpacing._sm} textAlign='center'>
              {t("web.general.client.policy")}
            </Font>
          )}
        </Provider>
      </AppContext.Provider>
    </HKEStyleProvider>
  );
}

export default ClientLayout;
